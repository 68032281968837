import React, { ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme, Hidden } from '@material-ui/core';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  //  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
  //    setRoomName(event.target.value);
  // };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  // if(!roomName){
  //   alert('precisamos da sala' + roomName)
  //   //window.location.href = 'https://app.interttie.com.br/Room'
  // }

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Entrar na sala de vídeo
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <InputLabel shrink htmlFor="input-user-name">
                Nome
              </InputLabel>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                onChange={handleNameChange}
              />
              <input type="hidden" id="input-room-name" value={roomName} />
            </div>
          )}
          {/* <div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-room-name">
              Sala
            </InputLabel>
            
          </div> */}
        </div>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!roomName}
            className={classes.continueButton}
          >
            Entrar
          </Button>
        </Grid>
      </form>
    </>
  );
}
